class Logger {

  constructor(namespace, level, enable=false) {
    this.ns = namespace;
    this.level = level;
    this.enable = enable;
    if (this.enable) {
      this.info(`${namespace}: log enabled`);
    }
  }

  formatArgs(args, level, colorLevel) {
    const arr = Array.from(args);
    arr.unshift( '%c'+level+' '+this.ns );
    arr.splice(1, 0, `color:${colorLevel}`);

    /*
    for (let i=0; i<arr.length; i++) {
      if (i>1) {
        if (typeof arr[i] === 'string') {
          arr[i] = '%c'+arr[i];
          arr.splice(i+1, 0, `color:${colorLevel}`);
          i++;
        }
      }
    }
    console.log(arr);
    */
    return arr;
  }

  info() {
    if (!this.enable) return;
    console.log.apply(console, this.formatArgs(arguments, 'INFO', 'cyan'));
  }

  warn() {
    console.log.apply(console, this.formatArgs(arguments, 'WARN', 'orange'));
  }

  debug() {
    if (!this.enable) return;
    console.log.apply(console, this.formatArgs(arguments, 'DEBUG', 'gray'));
  }

  error() {
    console.log.apply(console, this.formatArgs(arguments, 'ERROR', 'red'));
  }
}

export default Logger;