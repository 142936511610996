var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Notify from './Notify.js';
import Sleep from './Sleep.js';
import { Router } from '../Router.js';
class FetchRequest {
    constructor(url, options = {}, withToken = false) {
        this.controller = null;
        this.url = url;
        this.options = options;
        this.withToken = withToken;
        this.installController();
    }
    installController() {
        if (this.options.method !== 'POST')
            return;
        this.controller = new AbortController();
        this.options.signal = this.controller.signal;
        this.onAbort = this.onAbort.bind(this);
        window.addEventListener('abort-pending-xhr', this.onAbort);
        window.addEventListener('vaadin-router-location-changed', this.onAbort);
    }
    onAbort(ev) {
        var _a;
        (_a = this.controller) === null || _a === void 0 ? void 0 : _a.abort();
        this.uninstallController();
    }
    uninstallController() {
        if (!this.controller)
            return;
        window.removeEventListener('abort-pending-xhr', this.onAbort);
        window.removeEventListener('vaadin-router-location-changed', this.onAbort);
        this.controller = null;
    }
    execute() {
        return __awaiter(this, void 0, void 0, function* () {
            let attempts = 0;
            while (attempts < 3) {
                if (this.withToken) {
                    const token = yield this.addToken();
                    if (!token) {
                        attempts++;
                        yield Sleep(300);
                        continue;
                    }
                }
                try {
                    const response = yield fetch(this.url, this.options);
                    this.uninstallController();
                    return yield this.handleResponse(response);
                }
                catch (error) {
                    if (error.name === 'AbortError') {
                        console.warn('User canceled request');
                        return;
                    }
                    console.error('FetchRequest: Error during fetch operation', error);
                    throw error;
                }
            }
            throw new Error('FetchRequest: Failed after 3 attempts');
        });
    }
    addToken() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.options.headers) {
                this.options.headers = {};
            }
            this.options.headers['Content-Type'] = 'application/json';
            const token = yield FetchManager.getToken();
            if (!token)
                return;
            if (this.options.body) {
                // Si le corps est une chaîne JSON, analysez-la
                if (typeof this.options.body === 'string') {
                    const data = JSON.parse(this.options.body);
                    data.csrfToken = token;
                    this.options.body = JSON.stringify(data);
                }
                // Si le corps est un objet FormData, ajoutez le token
                else if (this.options.body instanceof FormData) {
                    this.options.body.append('csrfToken', token);
                }
                // Si le corps est un objet URLSearchParams, ajoutez le token
                else if (this.options.body instanceof URLSearchParams) {
                    this.options.body.set('csrfToken', token);
                }
                // Si le corps est de type ReadableStream, nous ne pouvons pas le modifier
                else {
                    console.warn('Unable to add CSRF token to a ReadableStream body');
                }
            }
            else {
                this.options.body = JSON.stringify({ csrfToken: token });
            }
            return token;
        });
    }
    handleResponse(response) {
        return __awaiter(this, void 0, void 0, function* () {
            if (response.status === 503) {
                Router.go('/public/errors/503');
                return;
            }
            if (response.status === 404) {
                Notify.errorCode('EHTTP_404', this.url);
                return;
            }
            if (response.status === 405) {
                Notify.errorCode('EHTTP_405', this.url);
                return;
            }
            if (response.status === 504) {
                Notify.errorCode('EHTTP_504', this.url);
                return;
            }
            const json = yield response.json();
            if (response.status !== 200) {
                if (json.error) {
                    return this.handleError(json);
                }
                throw new Error(`Unknown error: ${JSON.stringify(json)}`);
            }
            return json;
        });
    }
    handleError(json) {
        if (json.error === 'EVALIDATION_FAILED') {
            return json;
        }
        if (json.error === 'ETOKEN_SESSION_NOT_ACTIVE') {
            const event = new CustomEvent('session-expired');
            window.dispatchEvent(event);
            return;
        }
        Notify.errorCode(json.error, this.url, json.detail);
        if (json.error === 'EREFRESH_TOKEN_ERROR') {
            setTimeout(() => {
                console.log('Supposed to reload the page');
            }, 5000);
        }
    }
}
class FetchManager {
    constructor() {
        this.basePath = '/api/v2';
        this.queue = [];
        this.isProcessing = false;
        this.token = null;
        this.cache = {};
    }
    processQueue() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isProcessing || this.queue.length === 0) {
                return;
            }
            this.isProcessing = true;
            while (this.queue.length > 0) {
                const { fetchRequest, resolve, reject } = this.queue.shift();
                try {
                    const response = yield fetchRequest.execute();
                    resolve(response);
                }
                catch (error) {
                    reject(error);
                }
            }
            this.isProcessing = false;
        });
    }
    queueRequest(url, options, withToken = false) {
        return new Promise((resolve, reject) => {
            const fetchRequest = new FetchRequest(this.buildUrl(url), options, withToken);
            this.queue.push({ fetchRequest, resolve, reject });
            this.processQueue();
        });
    }
    buildUrl(url) {
        return url[0] !== '/' ? `${this.basePath}/${url}` : url;
    }
    /**
     * Effectue une requête GET avec mise en cache.
     * @param url - L'URL à solliciter.
     * @param ttl - Le TTL (en millisecondes) pour la mise en cache.
     * @returns La réponse mise en cache ou le résultat de la requête.
     */
    getCache(url, ttl) {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheKey = this.buildUrl(url);
            const cachedEntry = this.cache[cacheKey];
            // Vérifier si une entrée existe et si le TTL n'est pas expiré
            if (cachedEntry && Date.now() < cachedEntry.expiresAt) {
                //console.log(`Retour de la cache pour l'URL : ${cacheKey}`);
                return cachedEntry.data;
            }
            // Effectuer la requête si l'entrée n'existe pas ou si le TTL est expiré
            const data = yield this.get(url);
            // Stocker le résultat en cache avec le TTL
            this.cache[cacheKey] = {
                data,
                expiresAt: Date.now() + ttl,
            };
            return data;
        });
    }
    get(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.queueRequest(url, { method: 'GET' });
        });
    }
    post(url_1) {
        return __awaiter(this, arguments, void 0, function* (url, data = {}) {
            return yield this.queueRequest(url, { method: 'POST', body: JSON.stringify(data) }, true);
        });
    }
    delete(url_1) {
        return __awaiter(this, arguments, void 0, function* (url, data = {}) {
            return yield this.queueRequest(url, { method: 'DELETE', body: JSON.stringify(data) }, true);
        });
    }
    put(url_1) {
        return __awaiter(this, arguments, void 0, function* (url, data = {}) {
            return yield this.queueRequest(url, { method: 'PUT', body: JSON.stringify(data) }, true);
        });
    }
    search(url_1) {
        return __awaiter(this, arguments, void 0, function* (url, data = {}) {
            return yield this.queueRequest(url, { method: 'X-SEARCH', body: JSON.stringify(data) }, true);
        });
    }
    upload(url, formData) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.queueRequest(url, { method: 'POST', body: formData });
        });
    }
    postOrPut(url_1) {
        return __awaiter(this, arguments, void 0, function* (url, data = {}, id) {
            if (id)
                return yield this.put(`${url}/${id}`, data);
            return yield this.post(url, data);
        });
    }
    static getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const url = '/api/v2/public/token';
            const response = yield fetch(url);
            const json = yield response.json();
            return json === null || json === void 0 ? void 0 : json.tok;
        });
    }
    sendBeacon(url, data) {
        url = this.buildUrl(url);
        const jsonData = JSON.stringify(data);
        const success = navigator.sendBeacon(url, new Blob([jsonData], { type: 'application/json' }));
        if (!success) {
            console.error('Beacon failed to send');
        }
    }
}
const fetchManager = new FetchManager();
export default fetchManager;
