import '../pages/public/index.js';
import '../pages/private/index.js';

import { v4 as uuidv4 } from 'uuid';

function getOrCreateDeviceID() {
  let deviceID = localStorage.getItem('deviceID');
  if (!deviceID) {
      deviceID = uuidv4();
      localStorage.setItem('deviceID', deviceID);
  }
  return deviceID;
}

const config = {
  basePath: '/',
  language:localStorage.getItem('language') || 'french',
  deviceId: getOrCreateDeviceID(),
  localKeys:{
    theme:'theme',
    language:'language',
    layout:'center',
    sound:'sound',
  },
  get lg() {
    if (config.language === 'french') {
      return 'fr';
    } else {
      return 'en';
    }
  },
  lgMain: 'fr',
  miconGradient:false
}


export default {
  config,
  router:null
}
